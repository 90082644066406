<style lang="scss" scoped>
::v-deep .no_link .van-icon-arrow {
	color: #fff;
}
::v-deep .van-cell {
	padding: 0.4rem 0 0.2rem;
	border-bottom: 0.02rem solid #eeeff0;
	&:last-child {
		border-bottom: 0;
	}
}
.pv_4 {
	padding: 0 0.4rem;
}
.form {
	margin-top: 0.4rem;
}
.btn_box {
	margin-top: 40px;
	.loading_btn {
		border-radius: 8px;
		height: 0.8rem;
	}
	.btn {
		margin: 0.39rem 0.3rem 0.31rem;
		height: 0.8rem;
		border-radius: 8px;
		font-size: 0.3rem;
		text-align: center;
		color: #fff;
		background-color: $color_main;
		line-height: 0.8rem;
	}
}

.list {
	margin-top: 40px;
	.row_header {
		font-weight: bold;
	}
	.row {
		.label {
			color: $font_color_info;
			font-size: 14px;
			margin-right: 4px;
		}
		.btn {
			margin: 0.2rem 0;
			height: 0.8rem;
			border-radius: 8px;
			font-size: 0.3rem;
			text-align: center;
			color: #fff;
			line-height: 0.8rem;
			width: 50%;
		}
	}
	.row_btn {
		// text-align: right;
	}
}
</style>

<template>
	<div class="order_search">
		<!-- 订单查询 -->
		<top-nav ref="nav" @back="$router.go(-1)">{{ $t('orderSearch') }}</top-nav>
		<div class="pv_4">
			<van-form class="form" ref="form" input-align="right" error-message-align="right">
				<van-cell-group :border="false">
					<van-field :label="$t('name')" v-model="userInfo.name" class="no_link" label-class="cell_title" value-class="cell_value" :border="false" is-link :rules="rules.name" :placeholder="$t('namePlaceholder')" />
					<van-field :label="$t('cardNo')" v-model="userInfo.cardNum" label-class="cell_title" value-class="cell_value" :border="false" is-link class="no_link" :rules="rules.cardNum" :placeholder="$t('assuredCardNo')" />
					<div class="line"></div>
				</van-cell-group>
			</van-form>

			<div class="btn_box">
				<van-button class="loading_btn" @click="search" :loading="loading" type="info" color="#2594EF" block="" size="normal" :loading-text="$t('searching')">{{ $t('search') }}</van-button>
			</div>

			<van-list class="list">
				<van-cell v-for="(item, index) in list" :key="index">
					<div class="row row_header">
						<span class="name" v-text="item.proName"></span>
						<span class="plain" v-text="item.comboName"></span>
					</div>
					<div class="row">
						<span class="label">保障期限：</span>
						<span v-text="`${item.enabledate} 至 ${item.disenabledate}`"></span>
					</div>
					<div class="row">
						<span class="label">创建时间：</span>
						<span v-text="item.createTime"></span>
					</div>
					<div class="row row_btn" v-if="item.billState === 2">
						<van-button class="btn" type="primary" size="small" @click="pay(item)">支付</van-button>
					</div>
				</van-cell>
			</van-list>
		</div>
	</div>
</template>

<script>
import { Cell, CellGroup, Form, Field, Button, List, Toast } from 'vant';
import { getOrderByAssured } from '@/request/api';

export default {
	name: 'orderSearch', // 订单查询页面
	components: {
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Field.name]: Field,
		[Form.name]: Form,
		[Button.name]: Button,
		[List.name]: List,
	},
	data() {
		return {
			userInfo: {
				name: '',
				cardNum: '',
			},
			rules: {
				name: [{ required: true, message: '' }],
				cardNum: [{ required: true, message: '' }],
			},
			loading: false,

			list: [],
		};
	},
	beforeRouteEnter(to, from, next) {
		// 如果是从订单查询入口进来的，就清空缓存的查询结果
		if (from.name === 'productDetail') {
			next(vm => {
				vm.list = [];
			});
		}
		next();
	},
	methods: {
		search() {
			this.loading = true;
			this.$refs.form.validate().then(() => {
				getOrderByAssured(this.userInfo).then(res => {
					if (!res.rows.length) {
						Toast('未查询到信息');
					}
					this.list = res.rows;
					this.loading = false;
				});
			});
		},

		pay(info) {
			this.$store.commit('set_billId', info.billId);
			this.$router.push({
				name: 'orderPay',
				params: {
					isRedict: false,
				},
			});
		},
	},
};
</script>
